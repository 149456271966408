import store from '@src/reduxStore/store';
import { themeActions } from '@src/reduxStore/theme/themeSlice';
import { navigationActions } from '@src/reduxStore/navigation/navigationSlice';
import {
  selectLatestClusterChildren,
  selectNavTree,
} from '@src/reduxStore/navigation/navigationSelectors';
/* eslint-disable no-use-before-define */
const contentUtils = require('@src/app/contentUtils');
const utils = require('@src/app/utils');
import { HOMEPAGE_REFERENCES, NAVIGATION } from '@src/components/constants';
import { curriculumSitesLogic } from '@kathondvla/shared-logic';
import { fetchSitemap } from './sitemapService';
import uiState from '@src/reduxStore/ui/uiState';
import { isVisibleWithViewOptions } from '@src/helpers/viewOptionsHelper';
import { selectUserViewOptions } from '@src/reduxStore/user/userSelectors';
import { stateMatch } from 'redux-awaitable-state';
import { selectProThemes } from '@src/reduxStore/content/contentSelectors';
import { getHomePageItems } from '@src/reduxStore/content/contentHelpers';

const service = (settings, $location, routerService, referenceFrameService) => {
  'ngInject';
  'use strict';

  let items = [];
  let referenceFrameRecusiveChildren;
  let navigationTree = null;
  let initNavigationTree = null;
  let themes = null;
  let themeEntryPoint;

  const setCurrentThemeInReduxStore = (themeHref) => {
    store.dispatch(themeActions.setCurrentTheme(themeHref));
  };

  const data = {
    setThemeEntry,
    setLatestClusterChildren,
    getMatchingLatestClusterChild,
    init,
    buildProTree,
    getNavigationTreeAsync,
    getItems,
    getItemAsync,
    getFooterMenu,
    getQuickLinks,
    getSpotLightThemes,
    getAgenda,
    getBlog,
    getUserOptions,
    getHomepage,
    getCurrentNavigationItemTo,
    getBreadCrumbItems,
    buildRecursivePath,
    getRecursiveThemes,
    getThemesAndRecursiveChildren,
    getMenuItemAncestors,
    setCurrentThemeInReduxStore,
    getPathWithSubCurricula,
  };

  function setLatestClusterChildren(children) {
    console.log('setting latest cluster children');
    store.dispatch(navigationActions.setLatestClusterChildren(children));
  }

  function getMatchingLatestClusterChild(item) {
    const latestClusterChildren = selectLatestClusterChildren(store.getState());
    return (
      latestClusterChildren &&
      latestClusterChildren.find((child) => child.references.includes(item.href))
    );
  }

  function setThemeEntry(cluster) {
    themeEntryPoint = cluster;
  }

  // can be async (because everyone using it can be async), so we always use the primaryReferenceFrame (state), so we can remove "referenceFrameRecusiveChildren" var to reduce complexity
  /**
   *
   * @param {item from the menu structure} menuItem
   */
  function getRecursiveThemes(menuItem) {
    const flatmap = utils.flattenTree(menuItem); // flatten the menu item and its children
    const themes = flatmap
      .map((section) => {
        // /get all the linked themes of the reference frame
        if (section.theme) {
          if (section.theme.type === 'THEME') {
            return section.theme.$$meta.permalink;
          }
          if (section.theme.theme) {
            return section.theme.theme.$$meta.permalink;
          }
        }
      })
      .filter((e) => e);

    return getThemesAndRecursiveChildren(themes);
  }

  // can be async (because everyone using it can be async), so we always use the primaryReferenceFrame (state), so we can remove "referenceFrameRecusiveChildren" var to reduce complexity
  function getThemesAndRecursiveChildren(themes) {
    const themesAndRecursiveChildren = new Set(themes);
    themes.forEach((t) => {
      const children = referenceFrameRecusiveChildren.get(t); // get all the recursive children of the linked theme (sub items)
      children.forEach((item) => themesAndRecursiveChildren.add(item.href));
    });

    return [...themesAndRecursiveChildren]; // returns all themes and subthemes linked to this menu item
  }

  function setItemProperties(item, parent, viewOptions) {
    const referenceFrameTheme =
      item.themes && item.themes.length > 0
        ? referenceFrameService.getReferenceFrameItem(item.themes[0])
        : null;

    const icon = contentUtils.getImage(item, 'ICON');

    const itemWithProperties = {
      // this should be removed ASAP in favor of keeping here only the reference to the parentHref and then getting the element from the navigationTree selectors
      parent: parent
        ? {
            ...parent,
            children: null,
          }
        : null, // avoid circular references
      parentHref: parent?.href,
      href: item.$$meta.permalink,
      key: item.key ? item.key : null,
      type: item.type ? item.type : null,
      title: item.title ? item.title : null,
      shortDescription: item.shortdescription ? item.shortdescription : null,
      description: item.description ? item.description : null,
      coverImage: contentUtils.getImageWithSize(item, 'COVER_IMAGE', { w: 100, h: 100 }),
      theme: referenceFrameTheme && {
        ...referenceFrameTheme,
        // this is used to detect the "Kanaals" which, according to business, are the elements that
        // are linked to a THEME that is linked to the primary reference frame
        // Used only for being able to subscribe to them
        isChannel: referenceFrameTheme?.$$relationsFrom.some(
          (a) => a.$$expanded.to.href === NAVIGATION.PRIMARY_REFERENCE_FRAME
        ),
      },
      thumbImage: contentUtils.getImage(item, 'THUMBNAIL'),
      thumbImageM: contentUtils.getImageWithSize(item, 'THUMBNAIL', { w: 500, h: 360 }),
      thumbImageS: contentUtils.getImageWithSize(item, 'THUMBNAIL', { w: 100, h: 100 }),
      created: item.$$meta.created,
      issued: item.issued || (parent && parent.issued),
      importance: item.importance,
      $$relationsFrom: item.$$relationsFrom,
      $$relationsTo: item.$$relationsTo,
      coverage: item.coverage || parent.coverage,
      // mainstructures: item.mainstructures || parent.mainstructures,
      // outypes: item.outypes || parent.outypes,
      mainstructuresOuTypeCombinations:
        item.mainstructuresOuTypeCombinations || parent.mainstructuresOuTypeCombinations,
      references: contentUtils.addReferences(item),
      color:
        item.color ||
        (referenceFrameTheme && referenceFrameTheme.color) ||
        (parent && parent.color) ||
        settings.defaultColor,
      icon: icon ||
        (referenceFrameTheme && referenceFrameTheme.icon) ||
        (parent && parent.icon) || {
          href: settings.defaultIcon,
          contentType: 'image/svg+xml',
        },
    };

    return {
      ...itemWithProperties,
      visible: isVisibleWithViewOptions(itemWithProperties, viewOptions),
    };
  }

  function getReferencedTheme(item, viewOptions) {
    const references = item.$$relationsFrom.filter(
      (o) => o.$$expanded.relationtype === 'REFERENCES'
    );

    if (references.length > 0) {
      const theme = themes.get(references[0].$$expanded.to.href);

      if (theme) {
        return setItemProperties(theme, null, viewOptions);
      }
    }

    return null;
  }

  function findItem(href) {
    return items.find((o) => o.$$meta.permalink === href);
  }

  function getHomepage() {
    return navigationTree;
  }

  function addItems(parent, item, level, viewOptions) {
    parent.children = [];
    const isPartOfRelations = item.$$relationsTo.filter(
      (o) => o.$$expanded.relationtype === 'IS_PART_OF'
    );

    if (isPartOfRelations.length > 0) {
      isPartOfRelations.forEach((relation) => {
        const foundItem = findItem(relation.$$expanded.from.href);
        if (foundItem) {
          const webpageInfo = routerService.getWebpageInfoFromHref(foundItem.$$meta.permalink);
          const navItem = setItemProperties(foundItem, parent, viewOptions);

          navItem.level = level;
          navItem.readOrder = relation.$$expanded.readorder;
          navItem.coverImage = navItem.coverImage || item.coverImage;

          navItem.path = webpageInfo ? webpageInfo.path : null;

          if (!navItem.path && navItem.references.length > 0) {
            const reference = navItem.references[0];
            if (!reference.startsWith('/content/')) {
              try {
                const url = new URL(reference);
                navItem.path = reference;
                if ($location.$$host !== url.host) {
                  navItem.isPathExternal = true;
                }
              } catch (e) {}
            }
          }

          navItem.websiteType = webpageInfo ? webpageInfo.type : null;
          navItem.multipleAlternativeMenus = webpageInfo
            ? webpageInfo.multipleAlternativeMenus
            : false;

          if (
            parent.websiteType === 'FALLBACK_MENU_LEVEL_3' ||
            navItem.websiteType === 'FALLBACK_MENU_LEVEL_3' ||
            parent.parent?.websiteType === 'FALLBACK_MENU_LEVEL_3'
          ) {
            navItem.theme = getReferencedTheme(foundItem, viewOptions);
            if (navItem.theme) {
              const webInfo = routerService.getWebpageInfoFromHref(navItem.theme.href);
              navItem.path = webInfo ? webInfo.path : navItem.theme.href;

              navItem.coverage = navItem.theme.coverage ? navItem.theme.coverage : navItem.coverage;
              navItem.mainstructures = navItem.theme.mainstructures
                ? navItem.theme.mainstructures
                : navItem.mainstructures;
              navItem.outypes = navItem.theme.outypes ? navItem.theme.outypes : navItem.outypes;
              navItem.mainstructuresOuTypeCombinations = navItem.theme
                .mainstructuresOuTypeCombinations
                ? navItem.theme.mainstructuresOuTypeCombinations
                : navItem.mainstructuresOuTypeCombinations;
              navItem.issued = navItem.theme.issued;

              // we calculate again the visibility because the "coverage" and the "mainstructuresOuTypeCombinations" of the navItem may have changed
              navItem.visible = isVisibleWithViewOptions(navItem, viewOptions);
            }
          }

          addItems(navItem, foundItem, level + 1, viewOptions);

          parent.children.push(navItem);
        }
      });
      parent.children.sort((a, b) => a.readOrder - b.readOrder);
    }
  }

  function buildProTree(rootKey, homePageItems, viewOptions) {
    navigationTree = {};
    try {
      items = homePageItems;
      const root = homePageItems.find((o) => o.key === rootKey);
      const webpageInfo = routerService.getWebpageInfoFromHref(root.$$meta.permalink);
      navigationTree = {
        href: root.$$meta.permalink,
        type: root.type ? root.type : null,
        key: root.key ? root.key : null,
        title: root.title ? root.title : null,
        tags: root.tags ? root.tags : null,
        description: root.description ? root.description : null,
        created: root.$$meta.created,
        websiteType: webpageInfo ? webpageInfo.type : null,
        path: webpageInfo ? webpageInfo.path : null,
        visible: isVisibleWithViewOptions(root, viewOptions),
        issued: root.issued,
        coverage: root.coverage,
        mainstructures: root.mainstructures,
        outypes: root.outypes,
        mainstructuresOuTypeCombinations: root.mainstructuresOuTypeCombinations,
      };

      addItems(navigationTree, root, 0, viewOptions);
    } catch (e) {
      console.error(e);
    }

    console.debug('Pro navigation tree', navigationTree);
    return navigationTree;
  }

  async function init() {
    initNavigationTree = new Promise(async (resolve, reject) => {
      await Promise.all([routerService.getNeededWebpagesFromApi(), fetchSitemap()]);
      await stateMatch((state) => selectUserViewOptions(state));

      await stateMatch((state) => selectProThemes(state)?.length > 0);

      try {
        const proThemes = selectProThemes(store.getState());
        const homePageItems = await getHomePageItems();

        store.dispatch(themeActions.updateAugmentedProReferenceFrame({ content: homePageItems }));
        store.dispatch(
          themeActions.updateAugmentedProReferenceFrame({ content: proThemes, isProTheme: true })
        );

        themes = utils.arrayToMap(proThemes);

        // coming from primaryReferenceFrame
        referenceFrameRecusiveChildren =
          await referenceFrameService.getReferenceFrameRecursiveChildren();

        store.dispatch(navigationActions.setNavigationItems(homePageItems));

        const navTree = selectNavTree(store.getState());
        resolve(navTree);
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  }

  async function getNavigationTreeAsync() {
    return initNavigationTree;
  }

  function getItems() {
    return utils.flattenTree(navigationTree);
  }

  /**
   * Finds a menu item given its href
   * @param {*} href: the href of the element to find
   * @param {*} onlyVisible: whether or not to consider only menu items that are visible to the user according to their view options
   * @returns the found navigation item
   */

  async function getItemAsync(href, onlyVisible = false) {
    const navTree = await getNavigationTreeAsync();
    return utils.flattenTreeToMap(navTree, onlyVisible).get(href);
  }

  async function getFooterMenu() {
    const navTree = await getNavigationTreeAsync();
    return navTree
      ? navTree.children.find((item) => item.href === HOMEPAGE_REFERENCES.FOOTER)
      : undefined;
  }

  async function getQuickLinks() {
    const navTree = await getNavigationTreeAsync();
    return navTree
      ? navTree.children.find((item) => item.href === HOMEPAGE_REFERENCES.SNELLINKS).children
      : undefined;
  }

  async function getSpotLightThemes() {
    const navTree = await getNavigationTreeAsync();
    return navTree
      ? navTree.children.find((item) => item.href === HOMEPAGE_REFERENCES.SPOTLIGHTTHEMES).children
      : undefined;
  }

  async function getAgenda() {
    const navTree = await getNavigationTreeAsync();
    return navTree
      ? navTree.children.find((item) => item.href === HOMEPAGE_REFERENCES.AGENDA)
      : undefined;
  }

  async function getBlog() {
    const navTree = await getNavigationTreeAsync();
    return navTree
      ? navTree.children.find((item) => item.href === HOMEPAGE_REFERENCES.BLOG)
      : undefined;
  }

  function getUserOptions(user = null) {
    return user
      ? [
          {
            icon: 'icon-kov-pro-dd-viewer',
            title: ' kijkfilter',
            dispatch: uiState.actions.setIsSideBarOpen(true),
          },
          // { icon: 'icon-kov-pro-dd-favorite', title: ' mijn favorieten' },
          { type: 'divider' },
          { icon: 'icon-kov-pro-dd-profile', title: ' mijn profiel', event: 'appHome:editProfile' },
          {
            icon: 'icon-kov-pro-dd-training',
            title: ' mijn professionaliseringen',
            event: 'appHome:nascholing',
          },
          {
            icon: 'icon-kov-pro-leeromgeving',
            title: ' mijn leeromgeving',
            event: 'appHome:leerpaden',
          },
          {
            icon: 'icon-kov-pro-newsletter',
            title: ' mijn nieuwsbrieven',
            event: 'appHome:nieuwsbrieven',
          },
          { type: 'divider' },
          { icon: 'icon-kov-pro-cookie', title: ' cookie-instellingen', event: 'appHome:cookies' },
          { type: 'divider' },
          { icon: 'icon-kov-pro-dd-logout', title: ' afmelden', event: 'appHome:logout' },
        ]
      : [
          { icon: 'icon-kov-pro-dd-login', title: ' aanmelden', event: 'appHome:login' },
          { type: 'divider' },
          { icon: 'icon-kov-pro-cookie', title: ' cookie-instellingen', event: 'appHome:cookies' },
          { type: 'divider' },
          {
            icon: 'icon-kov-pro-dd-viewer',
            title: ' kijkfilter',
            dispatch: uiState.actions.setIsSideBarOpen(true),
          },
        ];
  }

  async function getCurrentNavigationItemTo(path, hideCurrent, href) {
    const homepageMenuItemsHref = HOMEPAGE_REFERENCES.MENU_ITEMS;

    const navTree = await getNavigationTreeAsync();
    const items = utils.flattenTree(navTree, false);
    let item = items.find((i) => i.path === path || (i.references && i.references.includes(href)));
    if (item && [2, 3].includes(item.level) && item.websiteType === 'FALLBACK_MENU_LEVEL_3') {
      themeEntryPoint = item;
    }
    if (item && hideCurrent) {
      item = item.parent;
    }

    const flatList = [];
    let iterationCount = 0;
    while (item && item.href !== homepageMenuItemsHref && iterationCount < 10) {
      if (!flatList.length || flatList[flatList.length - 1].href !== item.href) {
        flatList.push(item);
      }
      iterationCount++;

      if (
        themeEntryPoint &&
        themeEntryPoint.children.some(
          (child) => child.theme && item.theme && child.theme.href === item.theme.href
        )
      ) {
        item = themeEntryPoint;
      } else {
        if (item.parent && !item.parent.visible) {
          const alternativeParentKey = item.$$relationsFrom.find(
            (rel) => rel.$$expanded.to.href !== item.key
          ).$$expanded.to.href;
          const alternativeParent = items.find(
            (i) =>
              i.children &&
              i.children.find((c) => c.theme?.href === alternativeParentKey) &&
              i.key !== item.parent.key &&
              i.visible
          );
          item = alternativeParent ? alternativeParent : item.parent;
        } else {
          item = item.parent;
        }
      }
    }

    flatList.reverse();
    return flatList;
  }

  async function getBreadCrumbItems(path, hideCurrent = false, href) {
    const navigationItems = await getCurrentNavigationItemTo(path, hideCurrent, href);
    return navigationItems;
  }

  function buildRecursivePath(item, toLevel, excludeCurrent = true) {
    const arrayToReturn = excludeCurrent ? [] : [item];

    function add(i) {
      arrayToReturn.unshift(i);
      if (i.level > toLevel) {
        add(i.parent);
      }
    }
    if (item && item.level && item.parent) {
      if (item.level > toLevel) {
        add(item.parent);
      }
    }
    return tabCheck(arrayToReturn);
  }

  function tabCheck(pathArray) {
    return pathArray.map((pathItem) => {
      if (
        pathItem &&
        !pathItem.path &&
        pathItem.parent &&
        pathItem.parent.multipleAlternativeMenus &&
        pathItem.parent.path
      ) {
        return {
          ...pathItem,
          path: `${pathItem.parent.path}?tab=${pathItem.title}`,
        };
      }
      return pathItem;
    });
  }

  function getMenuItemAncestors(item, flattedNavTree) {
    const itemParent = flattedNavTree.find((a) => a.href === item.parentHref);
    return itemParent?.href !== HOMEPAGE_REFERENCES.MENU_ITEMS
      ? [...getMenuItemAncestors(itemParent, flattedNavTree), itemParent]
      : [];
  }

  function getPathWithSubCurricula(pathItem, subCurriculaHrefs) {
    if (subCurriculaHrefs.length === 0 || pathItem.isPathExternal) {
      return pathItem.pagePath;
    }
    return `${pathItem.pagePath}?curricula=${curriculumSitesLogic.encodeThemes(subCurriculaHrefs)}`;
  }

  return data;
};

angular.module('services').factory('navigationService', service);
